import React, { useEffect, useState } from 'react';
import './AudioPlayer.css';

const AudioPlayer = ({ audioUrlObject, onUnlockClick, userName }) => {

  const showPreview = useDelayedDisplay();

  function useDelayedDisplay(delay = 60000) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(true);
      }, delay);

      return () => clearTimeout(timer);
    }, [delay]);

    return isVisible;
  }

  return (
    <div className="audio-player-container">
      <div className="logo">
        <img src="ML_Logo_Hor_White.png" alt="ManifestLab Logo" className="logo-img" />
      </div>
      <h2>Your Personalized Manifestation Meditation Is Ready, {userName}!</h2>
      <div className="preview-instruction">
        <p>Click Play And Close Your Eyes to Enjoy a Free 3 Minute Preview</p>
        <p>(Headphones Recommended For Best Results):</p>
      </div>
      <div className="audio-container">
        {audioUrlObject && (
          <audio controls>
            <source src={audioUrlObject} type="audio/mp3" />
            Your browser does not support the audio element.
          </audio>
        )}
      </div>
      {showPreview && (
        <div className={`post-preview ${showPreview ? 'visible' : ''}`} id="post-preview">
          <div className="button-container">
            <b>Enjoyed The Free Preview?</b>
            <button className="styled-button" onClick={onUnlockClick}>
              Unlock Your Full 20 Minute Meditation
              <span className="arrow">➔</span>
            </button>
          </div>
          <div className="background-glow"></div>
        </div>
      )}
    </div>
  );
};

export default AudioPlayer;